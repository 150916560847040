import { checkExhausted } from "@cartographerio/util";
import type { ReactNode } from "react";
import React, { useMemo } from "react";
import "./Heading.css";
import classNames from "classnames";

export type HeadingLevel = "page" | "section" | "subsection" | "subsubsection";

export type HeadingAs = "h1" | "h2" | "h3" | "h4";

export type HeadingAlign = "left" | "center" | "right";

export interface HeadingProps {
  level: HeadingLevel;
  as?: HeadingAs;
  anchor?: string | null;
  align?: HeadingAlign;
  children: ReactNode;
}

const defaultClassNames: Record<HeadingAs, string> = {
  h1: "heading text-4xl",
  h2: "heading text-3xl",
  h3: "heading text-2xl",
  h4: "heading text-xl",
};

const alignClassNames: Record<HeadingAlign, string> = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

export default function Heading(props: HeadingProps) {
  const {
    level,
    as = defaultAs(level),
    anchor: anchorId,
    align = "left",
    children,
  } = props;

  const className = useMemo(
    () => classNames(defaultClassNames[as], alignClassNames[align]),
    [align, as]
  );

  const id = useMemo(
    () =>
      anchorId === null
        ? null
        : anchorId ??
          (typeof children === "string"
            ? children.replaceAll(/[^a-zA-Z0-9]+/g, "").toLowerCase()
            : undefined),
    [anchorId, children]
  );

  const anchor =
    id == null ? null : <div id={id} className="inline relative -top-24" />;

  const hash =
    id === null ? null : (
      <a href={`#${id}`} className="heading-anchor">
        #
      </a>
    );

  return React.createElement(as, { className }, anchor, children, " ", hash);
}

function defaultAs(level: HeadingLevel): HeadingAs {
  switch (level) {
    case "page":
      return "h1";
    case "section":
      return "h2";
    case "subsection":
      return "h3";
    case "subsubsection":
      return "h4";
    default:
      return checkExhausted(level);
  }
}
